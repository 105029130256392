._bg4586 {
  padding: 60px 0 0 !important;
}

._215b01 {
  float: left;
  width: 100%;
  background: #333;
  padding: 30px 0;
}

.preview_video {
  background: #fff;
  padding: 10px;
  float: left;
  width: 100%;
  border-radius: 3px;
}

._215b02 {
  position: absolute;
  text-align: center;
  bottom: 0;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  background: rgba(51, 51, 51, 0.9);
  left: 0;
  right: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 10px 0;
}

._215b03 h2 {
  font-size: 24px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-align: left;
  line-height: normal;
  margin-bottom: 15px;
}

._215b04 {
  font-size: 14px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  color: #f7f7f7;
  text-align: left;
  line-height: 26px;
}

._215b05 {
  margin-top: 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  text-align: left;
}

._215b06 {
  display: flex;
  margin-top: 15px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

._215b07 {
  margin-right: 20px;
}

.caption_tooltip {
  display: inline-block;
  position: relative;
}

.caption_tooltip:hover {
  text-decoration: underline;
  color: #f7f7f7;
  cursor: pointer;
}

.caption-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 200px;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  border: 1px solid rgba(34, 36, 38, 0.15);
  z-index: 1;
  right: 0;
  border-radius: 5px;
}

.caption-content span {
  color: #686f7a;
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  padding: 5px 10px;
  text-decoration: none;
  display: block;
}

.caption-content span i {
  margin-right: 6px;
  font-size: 115%;
}

.caption_tooltip:hover .caption-content {
  display: block;
}

._215b07 i {
  font-size: 120%;
}

._215b08 i {
  font-size: 120%;
}

._215b10 {
  width: 100%;
  float: left;
}

._215b11 {
  margin-top: 30px;
  color: #f3f3f3;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  text-align: left;
  float: left;
  margin-right: 30px;
}

._215b11:hover {
  color: #fff !important;
}

._215b12 {
  margin-top: 30px;
  color: #f3f3f3;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  text-align: left;
  float: left;
}

._215b12:hover {
  color: #fff !important;
}

._215b11 i {
  font-size: 120%;
  margin-right: 5px;
}

._215b12 i {
  font-size: 120%;
  margin-right: 5px;
}

._215b15 {
  float: left;
  width: 100%;
  padding: 20px 0 0;
  background: #fff;
  margin-bottom: 17px;
}

.course_tabs {
  float: left;
  width: 100%;
  margin-top: 10px;
}

.course_tabs .nav-item {
  margin-bottom: 0 !important;
}

.course_tabs .nav-tabs .nav-item.show .nav-link,
.course_tabs .nav-tabs .nav-link.active {
  color: #333 !important;
  background-color: transparent !important;
  border-color: inherit !important;
  border-top: 0 !important;
  border-bottom: 2px solid #ed2a26 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.course_tabs .nav-tabs {
  border-bottom: 0 !important;
}

.tab_crse .nav-link {
  border-top: 0 !important;
  border-bottom: 2px solid transparent !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  color: #333 !important;
  font-weight: 500 !important;
  font-family: "Roboto", sans-serif !important;
}

.tab_crse .nav-link {
  padding: 0.5rem 1.5rem !important;
}

._215b17 {
  float: left;
  width: 100%;
}

._df7852 {
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #333 !important;
  margin-top: 0;
  display: block;
  margin-bottom: 10px !important;
}

._df7852:hover {
  color: #333 !important;
}

._215b31 {
  width: 100%;
  margin-top: 27px;
}

._215b31 li {
  display: inline-block;
}

.btn_adcart {
  height: 40px;
  border: 0;
  padding: 0 20px;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  background: #ed2a26;
  border: 1px solid #ed2a26;
  margin-right: 6px;
}

.btn_adcart:hover {
  background: #c72127;
  border: 1px solid #c72127;
}

.btn_buy {
  height: 40px;
  border: 0;
  padding: 0 25px;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  background: transparent;
  border: 1px solid #fff;
}

.btn_buy:hover {
  color: #fff;
  background: #c72127;
  border: 1px solid #c72127;
}

._215fgt1 {
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  text-align: left;
}

._htg451 {
  margin-top: 30px;
  margin-bottom: 20px;
}

._htg452 h3 {
  font-size: 20px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-align: left;
  margin-bottom: 15px;
  color: #333;
}

._htg452 ul li {
  display: block;
  margin-bottom: 5px;
}

._htg452 ul li:last-child {
  margin-bottom: 0;
}

._5f7g11 {
  display: block;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #686f7a;
  text-align: left;
  line-height: 26px;
}

._5f7g11::before {
  content: "\f111";
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 6px;
  color: #333;
  vertical-align: bottom;
  margin-right: 10px;
  line-height: 26px;
}

._abc123 {
  display: block;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #333;
  text-align: left;
}

._htg452 p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #686f7a;
  text-align: left;
  margin-bottom: 15px;
  margin-top: 15px;
  line-height: 26px;
}

._abc124 {
  padding-left: 30px;
}

._htgdrt {
  background: #fff;
  padding: 30px;
  border: 1px solid #efefef;
  border-radius: 3px;
}

._htgdrt h3 {
  font-size: 20px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-align: left;
  margin-bottom: 15px;
  color: #333;
}

._abcd145 li {
  padding-bottom: 10px;
}

._abcd145 li:last-child {
  padding-bottom: 0;
}

._5f7g15 {
  display: flex;
}

._5f7g15 i {
  line-height: 28px;
  font-size: 14px;
  color: #333;
}

._5f7g15 span {
  display: block;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #686f7a;
  text-align: left;
  line-height: 26px;
  margin-left: 15px;
}

.crse_content {
  float: left;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
}

.crse_content h3 {
  font-size: 20px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-align: left;
  margin-bottom: 15px;
  color: #333;
  float: left;
}

._112456 {
  float: right;
  padding-top: 3px;
}

.accordion-expand-holder li {
  display: inline-block;
  margin-right: 30px;
  vertical-align: middle;
}

.accordion-expand-holder li:last-child {
  margin-right: 0;
}

._d1452 {
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-align: left;
  margin-bottom: 0;
  color: #333;
  cursor: pointer;
}

._d1452:hover {
  color: #ed2a26;
}

._fgr123 {
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-align: left;
  margin-bottom: 0;
  color: #686f7a;
}

.ui-accordion {
  margin-top: 4px;
  float: left;
  width: 100%;
}

.accordion-header {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  border: solid 1px #efefef;
  cursor: pointer;
  height: auto;
  margin-top: 3px;
  padding: 15px 30px 15px 20px;
  background: #fff;
  border-radius: 0px;
}

.accordion-header .section-header-left {
  display: flex;
  flex-direction: column;
  flex-basis: 65%;
}

.accordion-header .section-title-wrapper {
  flex-direction: row;
  align-items: center;
  display: flex;
  color: #505763;
  flex: 1 1 auto;
  font-size: 18px;
  font-weight: 600;
}

.accordion-header .section-title-text {
  font-size: 16px;
  padding-left: 8px;
  padding-right: 10px;
  color: #333;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.accordion-header .section-header-right {
  display: flex;
  justify-content: flex-end;
  color: #505763;
  flex-basis: 40%;
}

.accordion-header .num-items-in-section {
  display: block;
  margin-right: 20px;
  white-space: nowrap;
  font-size: 16px;
  color: #686f7a;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.accordion-header .section-header-length {
  text-align: right;
  width: 42%;
  font-size: 16px;
  color: #686f7a;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.crse_icon {
  font-size: 110%;
  margin-right: 5px;
}

.ui-accordion-content {
  float: left;
  width: 100%;
  border-left: 1px solid #efefef;
  border-right: 1px solid #efefef;
  background: #fff;
}

.ui-accordion-content .lecture-container {
  display: flex;
  padding: 12px 30px 12px 20px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  border-top: none;
  color: #686f7a;
  letter-spacing: 0.3px;
  line-height: 1.33;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.ui-accordion-content .left-content {
  display: flex;
  align-items: flex-start;
  width: 50%;
}

.ui-accordion-content .icon_142 {
  font-size: 18px;
  line-height: 18px;
  color: #afafaf;
}

.ui-accordion-content .top {
  flex-direction: column-reverse;
  display: flex;
  margin-left: 10px;
  width: 100%;
}

.ui-accordion-content .title {
  color: #686f7a;
  font-size: 14px;
  margin-top: -2px;
}

.ui-accordion-content .details {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-left: auto;
  width: 50%;
  font-size: 14px;
}

.ui-accordion-content .content-summary {
  color: #686f7a;
  flex-direction: row-reverse;
  display: flex;
  text-align: right;
  white-space: nowrap;
  width: 40%;
}

.ui-accordion-content .preview-text {
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #333;
}

.ui-accordion-content .preview-text:hover {
  color: #ed2a26 !important;
}

.btn1458 {
  float: left;
  margin-top: 5px;
  border: 1px solid #dedfe0;
  height: 50px;
  background: #e8e9eb;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #333;
  text-align: center;
  padding: 15px 0;
}

.btn1458:hover {
  border: 1px solid #e8e9eb;
  background: #e8e9eb;
  color: #333 !important;
}

.student_reviews {
  float: left;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
}

.reviews_left {
  float: left;
  width: 100%;
  background: #fff;
  padding: 20px 30px 30px;
  border: 1px solid #efefef;
  border-radius: 3px;
}

.reviews_left h3 {
  font-size: 20px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-align: left;
  margin-bottom: 20px;
  color: #333;
}

.total_rating {
  display: flex;
  width: 100%;
  font-size: 16px;
  justify-items: center;
  background: #f7f7f7;
  border: 1px solid #efefef;
  padding: 10px 20px;
  border-radius: 3px;
}

._rate001 {
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-align: left;
  margin-bottom: 0;
  color: #333;
  margin-right: 10px;
  line-height: 22px;
}

.rating-box {
  color: #dedfe0;
  display: flex;
  flex-basis: 30%;
}

.rating-star {
  font-size: 1.3rem;
  width: 1.3rem;
  height: 1.3rem;
  position: relative;
  display: block;
  float: left;
  margin-right: 7px;
}

.full-star:before {
  color: #f2b01e;
  content: "\f005";
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  left: 0;
}

.empty-star:before {
  content: "\f005";
  position: absolute;
  left: 0;
  overflow: hidden;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.half-star:before {
  color: #f2b01e;
  content: "\f005";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  width: 60%;
  position: absolute;
  left: 0;
  overflow: hidden;
}

.half-star:after {
  content: "\f005";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  left: 1.5rem;
  width: 60%;
  text-indent: -1.5rem;
}

._rate002 {
  font-size: 13px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-align: left;
  margin-bottom: 0;
  color: #333;
  margin-left: 10px;
  line-height: 22px;
}

._rate003 {
}

._rate004 {
  display: flex;
  cursor: pointer;
  height: auto;
  margin-top: 20px;
  border-radius: 0px;
}

.progress1 {
  display: flex;
  flex-basis: 50%;
  margin-right: 20px;
  height: 1.4rem !important;
}

.progress1 .progress-bar {
  background-color: #ed2a26 !important;
}

.w-70 {
  width: 70% !important;
}

.w-30 {
  width: 30% !important;
}

.w-5 {
  width: 10% !important;
}

.w-2 {
  width: 1% !important;
}

.w-1 {
  width: 0% !important;
}

.review_right {
  float: left;
  width: 100%;
}

.review_right_heading {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}

.review_right_heading h3 {
  font-size: 20px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-align: left;
  margin-bottom: 0;
  color: #333;
  float: left;
}

.review_search {
  float: right;
  width: 180px;
  position: relative;
}

.rv_srch {
  height: 30px;
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #333;
  padding-left: 15px;
  padding-right: 40px;
  width: 100%;
  border: 1px solid #efefef;
  border-radius: 3px;
}

.rvsrch_btn {
  position: absolute;
  right: 3px;
  top: 2px;
  border-radius: 3px;
  border: 0;
  height: 26px;
  width: 26px;
  background: #ed2a26;
  color: #fff;
  font-size: 14px;
}

.rvsrch_btn:hover {
  background: #333;
  color: #fff;
}

.review_all120 {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 3px;
}

.review_item {
  padding: 20px;
  border-bottom: 1px solid #efefef;
}

.review_item:last-child {
  border-bottom: 0;
}

.review_usr_dt {
  display: flex;
  position: relative;
}

.review_usr_dt img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  margin-right: 15px;
}

.tutor_name1 {
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #333 !important;
  text-align: left;
  margin-bottom: 0;
}

.time_145 {
  font-size: 13px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #686f7a;
  display: block;
  margin-top: 5px;
}

.rvds10 {
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin-top: 17px;
  line-height: 26px;
  color: #686f7a;
  text-align: left;
}

.rpt100 {
  display: flex;
  align-items: baseline;
}

.rpt100 span {
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #333;
  line-height: 24px;
  margin-right: 10px;
}

.radio--group-inline-container {
  padding: 5px 8px 0 8px;
  border-radius: 6px;
}

.more_reviews {
  display: block;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #333;
}

.radio--group-inline-container .radio-item {
  display: inline-block;
  margin-right: 10px;
}

.radio--group-inline-container .radio-item input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.radio--group-inline-container
  .radio-item
  input[type="radio"]
  + .radio-label:before {
  content: "";
  background: #f7f7f7;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1em;
  height: 1em;
  top: 0px;
  position: relative;
  margin-right: 10px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

.radio--group-inline-container
  .radio-item
  input[type="radio"]:checked
  + .radio-label:before {
  background-color: #ed2a26;
  box-shadow: inset 0 0 0 2px #f4f4f4;
}

.radio--group-inline-container
  .radio-item
  input[type="radio"]:focus
  + .radio-label:before {
  outline: none;
  border-color: #0e8a86;
}

.radio--group-inline-container
  .radio-item
  input[type="radio"]:disabled
  + .radio-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}

.radio--group-inline-container
  .radio-item
  input[type="radio"]
  + .radio-label:empty:before {
  margin-right: 0;
}

.radio-label {
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 12px;
  color: #333;
  margin-bottom: 0;
  cursor: pointer;
}

.report145 {
  font-size: 13px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #333;
  line-height: 24px;
  margin-left: 5px;
}

.vd_mdl .close {
  position: absolute;
  right: 10px;
  top: 8px;
  color: #fff;
  margin-left: auto;
  z-index: 1;
}

.vd_mdl .close:hover {
  color: #fff;
}

.vd_mdl .modal-body {
  padding: 0 !important;
}

.vd_mdl .modal-content {
  border-radius: 0 !important;
}

.vd_mdl .modal-body iframe {
  width: 100%;
  height: 375px;
  margin-bottom: -5px;
}

/* responsiveness */

@media (max-width: 575.98px) {
  .main_logo {
    margin-left: 5px !important;
    width: 110px !important;
  }

  .search120 {
    display: none;
    margin-left: 0 !important;
  }

  .upload_btn {
    display: none;
  }

  .header_right ul li:nth-child(2) {
    display: none !important;
  }

  .option_links {
    padding: 10px 3px !important;
    font-size: 18px !important;
  }

  .opts_account img {
    margin-left: 5px !important;
    width: 30px !important;
  }

  .menu.dropdown_mn {
    margin-right: -43px !important;
  }

  .menu.dropdown_ms {
    margin-right: -82px !important;
  }

  .noti_count {
    right: 0 !important;
  }

  .play_btn1 {
    top: 40%;
    right: 45% !important;
  }

  .value_props {
    text-align: center;
  }

  .value_icon {
    width: 100%;
  }

  .value_content {
    width: 100% !important;
    margin-top: 20px;
  }

  .value_content h4 {
    text-align: center !important;
  }

  .value_content p {
    text-align: center;
  }

  .get1452 {
    text-align: center !important;
  }

  .edu_social_links {
    text-align: left !important;
    margin-top: 10px;
  }

  .edu_social_links a:first-child {
    padding-left: 0;
  }

  .live1452 iframe {
    height: 190px !important;
  }

  .user_dt_right {
    float: left !important;
    width: 100%;
    margin-top: 30px;
  }

  .user_dt_right ul li {
    width: 24.3%;
  }

  ._215b03 {
    margin-top: 30px;
  }

  .tab_crse .nav-link {
    padding: 0.5rem 1rem !important;
  }

  .progress1 {
    flex-basis: 30% !important;
  }

  .reviews_left {
    margin-bottom: 50px;
  }

  ._216b22 {
    display: block !important;
  }

  ._216b12 {
    display: none !important;
  }

  .dp_dt150 {
    display: block !important;
  }

  .prfledt1 {
    text-align: center !important;
    margin-left: 0 !important;
  }

  .rgt-145 {
    text-align: center !important;
    margin-top: 30px !important;
  }

  ._bty149 {
    text-align: center !important;
  }

  .hf_img {
    width: 100% !important;
    float: left;
    position: relative;
  }

  .hs_content {
    padding: 10px 5px !important;
    float: left;
    width: 100% !important;
    position: relative;
  }

  .eps_dots10 {
    top: 10px !important;
  }

  .billing-percentages-progress-bar__labels li {
    width: 15% !important;
  }

  .radio--group-inline-container_1 li {
    margin-right: 5px !important;
  }

  .radio-label_1 {
    padding: 15px 10px !important;
  }

  ._215b03:before {
    background: 0 0 !important;
  }

  .ml_item {
    width: auto !important;
    text-align: left !important;
    padding-top: 13px;
    margin-left: 15px !important;
  }

  .hde151 {
    display: none;
  }

  .hde152 {
    display: block !important;
  }

  .titleright {
    float: left !important;
    width: 100%;
    margin-top: 30px;
  }

  .title589 p {
    padding: 0 !important;
  }

  .title478 {
    padding: 0 0 80px !important;
  }

  .prev_title {
    max-width: 100px !important;
  }

  .prev_title1 {
    max-width: 100px !important;
  }

  .story125 iframe {
    margin-bottom: 50px;
  }

  .career_item p {
    padding: 0 !important;
  }

  .membership__right {
    display: none;
  }

  .thnk_coming_title {
    font-size: 4em !important;
  }

  .tc_footer_left {
    width: 100%;
    text-align: center;
  }

  .tc_footer_right {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .cmtk_dt .clock li span {
    font-size: 3.5em !important;
  }

  .cmtk_dt .clock li label {
    font-size: 14px !important;
  }

  .cmtk_dt .clock li.sep {
    font-size: 2.5em !important;
  }

  .cmtk_dt {
    padding: 71px 0 61px !important;
  }

  .cert_banner_text h1 {
    font-size: 42px !important;
  }

  .edttslogo img {
    width: 80px !important;
  }

  .edttsplus img {
    width: 30px !important;
    margin: 0 20px !important;
  }

  .edttslogo1 img {
    width: 95px !important;
  }

  .result_dt h4 {
    height: 80px !important;
    width: 80px !important;
    padding: 25px 0 !important;
  }

  .result_dt .right_ans {
    height: 80px !important;
    width: 80px !important;
    padding: 16px 0 !important;
  }

  .result_dt .wrong_ans {
    height: 80px !important;
    width: 80px !important;
    padding: 16px 0 !important;
  }

  .result_content h2 {
    font-size: 36px !important;
  }

  .title478 {
    padding: 62px 0 50px !important;
  }

  .top-category {
    display: none;
  }

  .lecture-header-left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
  }

  .lecture-sidebar {
    display: none;
    width: 100%;
  }

  .lecture-container {
    margin-left: 0;
    padding: 30px 20px;
  }

  .lecture-content-inner-video {
    height: auto !important;
  }

  .video-responsive iframe {
    position: relative;
    width: 100vw;
    height: 55vw;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .main_logo {
    margin-left: 10px !important;
  }

  .search120 {
    display: none;
    margin-left: 0 !important;
  }

  .value_props {
    text-align: center;
  }

  .value_icon {
    width: 100%;
  }

  .value_content {
    width: 100% !important;
    margin-top: 20px;
  }

  .value_content h4 {
    text-align: center !important;
  }

  .value_content p {
    text-align: center;
  }

  .get1452 {
    text-align: center !important;
  }

  .edu_social_links {
    text-align: left !important;
    margin-top: 10px;
  }

  .edu_social_links a:first-child {
    padding-left: 0;
  }

  ._215b03 {
    margin-top: 30px;
  }

  .reviews_left {
    margin-bottom: 50px;
  }

  ._216b22 {
    display: block !important;
  }

  ._216b12 {
    display: none !important;
  }

  .dp_dt150 {
    display: block !important;
  }

  .prfledt1 {
    text-align: center !important;
    margin-left: 0 !important;
  }

  .rgt-145 {
    text-align: center !important;
    margin-top: 30px !important;
  }

  ._bty149 {
    text-align: center !important;
  }

  .hf_img {
    width: 100% !important;
    float: left;
    position: relative;
  }

  .hs_content {
    padding: 10px 5px !important;
    float: left;
    width: 100% !important;
    position: relative;
  }

  .eps_dots10 {
    top: 10px !important;
  }

  ._215b03:before {
    background: 0 0 !important;
  }

  .ml_item {
    width: auto !important;
    text-align: center !important;
    margin-left: 15px !important;
  }

  .titleright {
    float: left !important;
    width: 100%;
    margin-top: 30px;
  }

  .title589 p {
    padding: 0 !important;
  }

  .title478 {
    padding: 0 0 80px !important;
  }

  .story125 iframe {
    margin-bottom: 50px;
  }

  .career_item p {
    padding: 0 !important;
  }

  .tc_footer_left {
    width: 100%;
    text-align: center;
  }

  .tc_footer_right {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .thnk_coming_title {
    font-size: 10em !important;
  }

  .cmtk_dt .clock li span {
    font-size: 6em !important;
  }

  .cmtk_dt .clock li label {
    font-size: 16px !important;
  }

  .cmtk_dt .clock li.sep {
    font-size: 5em !important;
  }

  .cmtk_dt {
    padding: 71px 0 61px !important;
  }

  .cert_banner_text h1 {
    font-size: 42px !important;
  }

  .edttslogo img {
    width: 80px !important;
  }

  .edttsplus img {
    width: 30px !important;
    margin: 0 20px !important;
  }

  .edttslogo1 img {
    width: 95px !important;
  }

  .title478 {
    padding: 62px 0 50px !important;
  }

  .top-category {
    display: none;
  }

  .lecture-header-left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
  }

  .lecture-sidebar {
    display: none;
    width: 100%;
  }

  .lecture-container {
    margin-left: 0;
    padding: 30px 20px;
  }

  .lecture-content-inner-video {
    height: auto !important;
  }

  .video-responsive iframe {
    position: relative;
    width: 100vw;
    height: 40vw;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .main_logo {
    margin-left: 10px !important;
  }

  .search120 {
    display: none;
    margin-left: 0 !important;
  }

  .value_props {
    text-align: center;
  }

  .value_icon {
    width: 100%;
  }

  .value_content {
    width: 100% !important;
    margin-top: 20px;
  }

  .value_content h4 {
    text-align: center !important;
  }

  .value_content p {
    text-align: center;
  }

  .get1452 {
    text-align: center !important;
  }

  .rating-box {
    flex-basis: 20% !important;
  }

  .reviews_left {
    margin-bottom: 50px;
  }

  ._216b22 {
    display: block !important;
  }

  ._216b12 {
    display: none !important;
  }

  .dp_dt150 {
    display: block !important;
  }

  .prfledt1 {
    text-align: center !important;
    margin-left: 0 !important;
  }

  .rgt-145 {
    text-align: center !important;
    margin-top: 30px !important;
  }

  ._bty149 {
    text-align: center !important;
  }

  .hf_img {
    width: 100% !important;
    float: left;
    position: relative;
  }

  .hs_content {
    padding: 10px 5px !important;
    float: left;
    width: 100% !important;
    position: relative;
  }

  .eps_dots10 {
    top: 10px !important;
  }

  ._215b03:before {
    background: 0 0 !important;
  }

  .ml_item {
    width: auto !important;
    text-align: center !important;
    margin-left: 15px !important;
  }

  .title589 p {
    padding: 0 50px !important;
  }

  .career_item p {
    padding: 0 !important;
  }

  .tc_footer_left {
    width: 100%;
    text-align: center;
  }

  .tc_footer_right {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .lecture-header-left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 149px;
    flex: 0 0 149px;
  }

  .lecture-sidebar {
    display: none;
    width: 100%;
  }

  .lecture-container {
    margin-left: 0;
    padding: 30px 20px;
  }

  .lecture-content-inner-video {
    height: auto !important;
  }

  .video-responsive iframe {
    position: relative;
    width: 100vw;
    height: 55vw;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  body {
    overflow-y: scroll;
  }

  .logo img {
    width: 200px;
    text-align: center;
    margin-top: 10px;
    float: none;
  }

  .wrapper__minify {
    margin-left: 50px;
  }

  .search120 {
    display: none;
    margin-left: 0 !important;
  }

  .value_props {
    text-align: center;
  }

  .value_icon {
    width: 100%;
  }

  .value_content {
    width: 100% !important;
    margin-top: 20px;
  }

  .value_content h4 {
    text-align: center !important;
  }

  .value_content p {
    text-align: center;
  }

  .get1452 {
    text-align: center !important;
  }

  .ui-accordion-content .content-summary {
    width: 30% !important;
  }

  .title900 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .hf_img {
    width: 40% !important;
  }

  .hs_content {
    width: 60% !important;
  }

  .ml_item {
    width: 78% !important;
  }

  .title589 p {
    padding: 0 160px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px !important;
  }

  .hf_img {
    width: 40% !important;
  }

  .hs_content {
    width: 60% !important;
  }

  .container-fluid {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
}
/* vertical responsive */

.collapse_menu .collapse_menu--icon,
.vertical_nav {
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
* {
  box-sizing: border-box;
}
:active,
:focus {
  outline: 0;
}

.toggle_menu {
  display: block;
  float: left;
  line-height: 57px;
  padding: 0;
  background: 0 0;
  border: 0;
  font-size: 22px;
  text-align: center;
  cursor: pointer;
}

.toggle_menu i {
  width: 50px;
  text-align: center;
  display: block;
  padding: 0;
  border: 0;
  background: #fff;
  color: #737373;
  font-size: 120%;
  margin: 0;
  cursor: pointer;
  float: left;
}

.toggle_menu i:hover {
  color: #ed2a26;
}

.vertical_nav {
  position: fixed;
  left: -240px;
  top: 60px;
  bottom: 0;
  background: #fff;
  overflow: hidden;
  width: 240px;
  z-index: 995;
}

.vertical_nav__closed {
  left: -240px !important;
}

.vertical_nav__opened {
  left: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.2)-30px 0 30px 30px;
}

.menu_left {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.menu_left:hover {
  overflow-y: scroll;
}

.menu--item {
  position: relative;
  min-height: 42px;
  line-height: 42px;
}

.menu--link:hover {
  background: #ffecec;
  color: #ed2a26 !important;
}

.menu--link.active {
  background: #ffecec;
  color: #ed2a26;
}

.menu--item__has_sub_menu .menu--link:after {
  position: absolute;
  top: 0;
  right: 15px;
  height: 45px;
  line-height: 45px;
  font-family: "Font Awesome 5 Free";
  content: "\f0d7";
  font-weight: 900;
}

.menu--subitens__opened {
  background: #ffecec;
}

.menu--subitens__opened .menu--link {
  color: #333;
}

.menu--subitens__opened .sub_menu {
  display: block;
}

.menu--link {
  display: block;
  overflow: hidden;
  font-size: 14px;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}

.menu--link:hover,
.menu--link:hover .menu--label {
  background: #ffecec;
}

.menu--icon {
  display: block;
  float: left;
  width: 50px;
  height: 45px;
  line-height: 43px;
  font-size: 20px;
  text-align: center;
}

.menu--label {
  display: block;
  height: 45px;
  line-height: 43px;
}

.sub_menu {
  display: none;
  overflow: hidden;
  padding-left: 0;
  list-style: none;
}

.sub_menu--link {
  display: block;
  padding-left: 50px;
  padding-right: 16px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #333;
  text-decoration: none;
}

.sub_menu--link:hover {
  color: #ed2a26 !important;
  background: #ffecec;
}

.sub_menu--link.active {
  color: #da1f27;
  font-weight: 500;
}

.collapse_menu {
  display: none;
}

@media (min-width: 992px) {
  .vertical_nav {
    left: 0;
  }

  .toggle_menu {
    display: none;
  }

  .vertical_nav__minify {
    width: 0;
  }

  .vertical_nav__minify .menu {
    overflow: visible;
  }

  .vertical_nav__minify .menu--label {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    padding-left: 16px;
    padding-right: 16px;
    background: #e9e9e9;
    font-weight: 500;
  }

  .vertical_nav__minify .menu--item__has_sub_menu .menu--link:after {
    content: "";
  }

  .vertical_nav__minify .menu--subitens__opened .menu--label,
  .vertical_nav__minify .menu--subitens__opened .sub_menu {
    display: block;
  }

  .vertical_nav__minify .sub_menu {
    position: absolute;
    top: 40px;
    left: 50px;
    width: 150px;
    background: #e9e9e9;
  }

  .vertical_nav__minify .sub_menu--link {
    padding-left: 16px;
  }

  .vertical_nav__minify .collapse_menu--icon {
    -webkit-transform: rotate(180deg);
  }

  .collapse_menu {
    width: 60px;
    text-align: center;
    display: block;
    height: 60px;
    padding: 0;
    border: 0;
    background: #ed2a26;
    color: #fff;
    font-size: 30px;
    margin: 0;
    cursor: pointer;
    float: left;
  }

  .collapse_menu:hover {
    color: #fff;
    background: #af1f24;
  }

  .collapse_menu .collapse_menu--icon {
    display: block;
    float: left;
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 22px;
  }

  .collapse_menu .collapse_menu--label {
    display: block;
    height: 40px;
    line-height: 40px;
  }
}
