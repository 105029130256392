.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.badge {
  position: relative;
  top: -9px;
  right: 3px;
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 100%;
}
.collapseicon {
  position: relative;
  top: -20px;
  left: -40px;
}
.logo {
  top: -50px;
}

.offline_user_actions {
  color: red;
  top: 3rem;
  padding: 32;
  font-size: 9px;
}
.live_user_actions {
  color: rgb(21, 119, 18);
  top: 3rem;
  padding: 32;
  font-size: 9px;
}

.transition-all {
  transition-property: all;
  transition-timing-function: ease-in-out;
}

.duration-500 {
  transition-duration: 500ms;
}
.panel-body {
  padding: 10px;
}

/* Style the collapsed state */
.panel-body {
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #686f7a;
  line-height: 26px;
  padding-bottom: 20px;
  text-align: left;
}
.collapse .panel-body {
  display: none;
}

/* Style the expanded state */
.panel-collapse.show .panel-body {
  display: block;
}

/* .badge {
  @apply relative top-0  right-0 bg-red-500 text-white rounded-full p-1 mb-8 text-xs ;
} */
